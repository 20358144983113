import { render, staticRenderFns } from "./SkinsAdd.vue?vue&type=template&id=0d882c2e&scoped=true&"
import script from "./SkinsAdd.vue?vue&type=script&lang=js&"
export * from "./SkinsAdd.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d882c2e",
  null
  
)

export default component.exports